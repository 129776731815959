import React from 'react';
import aiEnergyImage from '../images/ai-energy-solution.webp';

export default function AIEnergy() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl w-full bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="flex flex-col md:flex-row" style={{ height: '66vh' }}>
          <div className="w-full md:w-1/2 p-8 overflow-y-auto">
            <h2 className="text-3xl font-bold mb-6">AI-Powered Energy Monitoring Solutions</h2>
            <p className="mb-4">
              At Delta Telematics, we leverage cutting-edge artificial intelligence to revolutionize energy monitoring for Canadian businesses. Our AI-powered solutions provide real-time insights, predictive analytics, and automated optimization to help you reduce energy consumption, cut costs, and minimize your environmental footprint.
            </p>
            <h3 className="text-xl font-semibold mt-4 mb-2">Key Features:</h3>
            <ul className="list-disc pl-5 mb-4">
              <li>Real-time energy consumption monitoring across all systems and equipment</li>
              <li>AI-driven predictive maintenance to prevent energy waste from faulty equipment</li>
              <li>Automated load balancing and peak demand management</li>
              <li>Customized energy-saving recommendations based on your unique usage patterns</li>
              <li>Integration with renewable energy sources for optimal utilization</li>
            </ul>
            <p>
              Our energy monitoring solutions are tailored to meet the specific needs of various industries across Canada, including manufacturing, commercial real estate, and natural resources. By harnessing the power of AI, we help you make data-driven decisions that lead to significant energy savings and improved operational efficiency.
            </p>
          </div>
          <div className="w-full md:w-1/2 bg-gray-300">
            {/* Placeholder for the image */}
            <img 
              src={aiEnergyImage}
              alt="AI Energy Monitoring" 
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
