import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from './images/logo.gif';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import AIEnergy from './components/AIEnergy';
import VehicleAnalytics from './components/VehicleAnalytics';
import CloudSolutions from './components/CloudSolutions';
import Contact from './components/Contact';

function App() {
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const timeoutRef = useRef(null);
  const servicesRef = useRef(null);

  const handleMouseEnter = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setIsServicesOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsServicesOpen(false);
    }, 300); // 300ms delay before closing
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <header className="bg-white shadow-md">
          <div className="container mx-auto px-4 py-2 flex justify-between items-center">
            <div className="flex items-center">
              <Link to="/">
                <img 
                  src={logo} 
                  alt="Delta Telematics Logo" 
                  className="w-16 h-16 object-contain mr-2"
                />
              </Link>
              <h1 className="text-2xl font-bold text-gray-800">Delta Telematics</h1>
            </div>
            <nav>
              <ul className="flex space-x-4">
                <li><Link to="/" className="text-gray-600 hover:text-orange-500">Home</Link></li>
                <li><Link to="/about" className="text-gray-600 hover:text-orange-500">About</Link></li>
                <li 
                  className="relative"
                  ref={servicesRef}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <Link 
                    to="/services" 
                    className="text-gray-600 hover:text-orange-500"
                  >
                    Services
                  </Link>
                  {isServicesOpen && (
                    <ul 
                      className="absolute left-0 mt-0 w-48 bg-white border border-gray-200 rounded-md shadow-lg py-1 z-10"
                    >
                      <li>
                        <Link to="/services/ai-energy" className="block px-4 py-2 text-sm text-gray-700 hover:bg-orange-500 hover:text-white">
                          AI Energy Solutions
                        </Link>
                      </li>
                      <li>
                        <Link to="/services/vehicle-analytics" className="block px-4 py-2 text-sm text-gray-700 hover:bg-orange-500 hover:text-white">
                          Vehicle Analytics
                        </Link>
                      </li>
                      <li>
                        <Link to="/services/cloud-solutions" className="block px-4 py-2 text-sm text-gray-700 hover:bg-orange-500 hover:text-white">
                          Cloud Solutions
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li><Link to="/contact" className="text-gray-600 hover:text-orange-500">Contact</Link></li>
              </ul>
            </nav>
          </div>
        </header>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/ai-energy" element={<AIEnergy />} />
          <Route path="/services/vehicle-analytics" element={<VehicleAnalytics />} />
          <Route path="/services/cloud-solutions" element={<CloudSolutions />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>

        <footer className="bg-gray-800 text-white py-4 mt-8">
          <div className="container mx-auto text-center">
            <p>&copy; 2024 Delta Telematics Canada Inc. | Privacy Policy | Terms of Service</p>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
