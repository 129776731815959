import React from 'react';
import aboutImage from '../images/about-image.webp'; // Make sure this path is correct

export default function About() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl w-full bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="flex flex-col md:flex-row" style={{ height: '66vh' }}>
          <div className="w-full md:w-1/2 p-8 overflow-y-auto">
            <h2 className="text-3xl font-bold mb-6">About Delta Telematics</h2>
            <p className="mb-4">
              Founded in 2015, Delta Telematics Canada Inc. has been at the forefront of technological innovation in the telematics industry. Our team of expert engineers, data scientists, and designers work tirelessly to create solutions that transform industries and improve lives across Canada.
            </p>
            <p className="mb-4">
              With a focus on artificial intelligence, cloud computing, and the Internet of Things, we're committed to pushing the boundaries of what's possible in the digital age. Our solutions cater specifically to the needs of Canadian businesses, helping them optimize operations, reduce costs, and enhance security.
            </p>
            <p className="mb-4">
              At Delta Telematics, we pride ourselves on our ability to deliver cutting-edge technology while maintaining a deep understanding of the unique challenges faced by Canadian industries. From energy monitoring in the oil sands to fleet management in urban centers, we're here to drive innovation and efficiency across the nation.
            </p>
            <h3 className="text-xl font-semibold mt-6 mb-3">Our Mission</h3>
            <p className="mb-4">
              To empower Canadian businesses with innovative telematics solutions that drive efficiency, safety, and sustainability in an increasingly connected world.
            </p>
            <h3 className="text-xl font-semibold mt-6 mb-3">Our Vision</h3>
            <p>
              To be the leading provider of AI-powered telematics solutions in Canada, revolutionizing how businesses operate and interact with their assets and environment.
            </p>
          </div>
          <div className="w-full md:w-1/2 bg-gray-300">
            <img 
              src={aboutImage}
              alt="Delta Telematics Team" 
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
