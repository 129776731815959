import React from 'react';
import vehicleAnalyticsImage from '../images/vehicle-analytics-solution.webp';

export default function VehicleAnalytics() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl w-full bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="flex flex-col md:flex-row" style={{ height: '66vh' }}>
          <div className="w-full md:w-1/2 p-8 overflow-y-auto">
            <h2 className="text-3xl font-bold mb-6">Vehicle Performance and Security Analytics</h2>
            <p className="mb-4">
              Delta Telematics offers state-of-the-art vehicle analytics solutions designed to enhance fleet performance, improve security, and optimize operations for businesses across Canada. Our AI-powered platform provides comprehensive insights into your vehicle fleet, enabling data-driven decision-making and proactive management.
            </p>
            <h3 className="text-xl font-semibold mt-4 mb-2">Key Features:</h3>
            <ul className="list-disc pl-5 mb-4">
              <li>Real-time GPS tracking and geofencing capabilities</li>
              <li>Advanced driver behavior analysis for improved safety and efficiency</li>
              <li>Predictive maintenance alerts to prevent breakdowns and reduce downtime</li>
              <li>Fuel consumption optimization and route planning</li>
              <li>Vehicle security monitoring with instant alerts for unauthorized use</li>
              <li>Customizable reporting and analytics dashboard</li>
            </ul>
            <p>
              Our vehicle analytics platform is built to address the unique challenges faced by Canadian businesses, from navigating harsh weather conditions to optimizing long-haul routes across vast distances. Whether you're managing a small local fleet or a large national operation, our scalable solutions adapt to your needs.
            </p>
          </div>
          <div className="w-full md:w-1/2 bg-gray-300">
            <img 
              src={vehicleAnalyticsImage}
              alt="Vehicle Analytics" 
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
