import React from 'react';
import { Link } from 'react-router-dom';
import homeBannerImage from '../images/home_banner.webp';

export default function Home() {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <div className="relative h-[60vh] bg-gray-300">
          {/* Placeholder for banner image */}
          <img 
            src={homeBannerImage} 
            alt="Delta Telematics Banner" 
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="text-center">
              <h1 className="text-4xl md:text-6xl font-bold text-white mb-4">
                Innovating Telematics for Tomorrow
              </h1>
              <p className="text-xl md:text-2xl text-white mb-8">
                We create cutting-edge AI-powered solutions for energy monitoring, vehicle analytics, and cloud-hosted enterprise solutions.
              </p>
              <Link 
                to="/contact" 
                className="bg-orange-500 text-white px-6 py-3 rounded-lg hover:bg-orange-600 transition duration-300"
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>

        <section className="py-16 px-4">
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold mb-8 text-center">Our Services</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <ServiceCard 
                title="AI Energy Solutions" 
                description="Optimize energy consumption with our AI-powered monitoring systems."
                link="/services/ai-energy"
              />
              <ServiceCard 
                title="Vehicle Analytics" 
                description="Enhance fleet performance and security with our advanced analytics."
                link="/services/vehicle-analytics"
              />
              <ServiceCard 
                title="Cloud Solutions" 
                description="Scalable and secure cloud infrastructure for Canadian businesses."
                link="/services/cloud-solutions"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

function ServiceCard({ title, description, link }) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md text-center">
      <h3 className="text-xl font-bold mb-2">{title}</h3>
      <p className="mb-4">{description}</p>
      <Link to={link} className="text-orange-500 hover:underline">Learn More</Link>
    </div>
  );
}
