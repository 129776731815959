import React from 'react';
import cloudSolutionsImage from '../images/cloud-solutions.webp';

export default function CloudSolutions() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl w-full bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="flex flex-col md:flex-row" style={{ height: '66vh' }}>
          <div className="w-full md:w-1/2 p-8 overflow-y-auto">
            <h2 className="text-3xl font-bold mb-6">Cloud-Hosted Enterprise Solutions</h2>
            <p className="mb-4">
              Delta Telematics provides robust, scalable, and secure cloud-hosted enterprise solutions tailored for small businesses in Canada. Our cloud services empower your organization with cutting-edge technology, allowing you to focus on your core business while we handle the complexities of IT infrastructure.
            </p>
            <h3 className="text-xl font-semibold mt-4 mb-2">Our Cloud Offerings:</h3>
            <ul className="list-disc pl-5 mb-4">
              <li>Infrastructure as a Service (IaaS): Scalable computing resources on-demand</li>
              <li>Platform as a Service (PaaS): Streamlined application development and deployment</li>
              <li>Software as a Service (SaaS): Access to enterprise-grade software without the overhead</li>
              <li>Data storage and backup solutions with Canadian data residency</li>
              <li>Disaster recovery and business continuity planning</li>
              <li>Cloud security and compliance management</li>
            </ul>
            <h3 className="text-xl font-semibold mt-4 mb-2">Consulting Services:</h3>
            <p className="mb-4">
              Our team of expert consultants is dedicated to helping small businesses in Canada navigate the complexities of cloud adoption and digital transformation. We offer cloud readiness assessments, custom strategy development, implementation services, and ongoing support to ensure your cloud solutions are optimized for your specific needs.
            </p>
          </div>
          <div className="w-full md:w-1/2 bg-gray-300">
            <img 
              src={cloudSolutionsImage}
              alt="Cloud Solutions" 
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
