import React, { useState } from 'react';
import contactUsImage from '../images/contact-us.png';

export default function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      const response = await fetch('https://cukjar0v62.execute-api.us-east-2.amazonaws.com/default/Delta_Telematics_Web_Contacts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Form submitted successfully:', result);
      setSubmitStatus('success');
      setFormData({ name: '', email: '', subject: '', message: '' });
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl w-full bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="flex flex-col md:flex-row" style={{ height: 'vh' }}>
          <div className="w-full md:w-1/2 p-8 overflow-y-auto">
            <h2 className="text-3xl font-bold mb-6">Contact Us</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="name" className="block text-gray-700 font-bold mb-2">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700 font-bold mb-2">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="subject" className="block text-gray-700 font-bold mb-2">Subject</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block text-gray-700 font-bold mb-2">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full bg-orange-500 text-white py-2 px-4 rounded-lg hover:bg-orange-600 transition duration-300 disabled:opacity-50"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Sending...' : 'Send Message'}
              </button>
            </form>
            {submitStatus === 'success' && (
              <div className="mt-4 p-3 bg-green-100 text-green-700 rounded-lg">
                Thank you for your message. We will get back to you soon!
              </div>
            )}
            {submitStatus === 'error' && (
              <div className="mt-4 p-3 bg-red-100 text-red-700 rounded-lg">
                There was an error submitting your message. Please try again later.
              </div>
            )}
          </div>
          <div className="w-full md:w-1/2 bg-gray-100 p-8 flex flex-col">
            <img 
              src={contactUsImage}
              alt="Contact Us" 
              className="w-full h-auto mb-6"
            />
            <h3 className="text-2xl font-bold mb-4">Get in Touch</h3>
            <p className="mb-4">We're here to help and answer any question you might have. We look forward to hearing from you!</p>
            <div className="mb-4">
              <h4 className="font-bold">Email:</h4>
              <p>Sales Inquiries: <a href="mailto:sales@delta-telematics.ca" className="text-orange-500 hover:underline">sales@delta-telematics.ca</a></p>
              <p>Support: <a href="mailto:support@delta-telematics.ca" className="text-orange-500 hover:underline">support@delta-telematics.ca</a></p>
            </div>
            <div>
              <h4 className="font-bold">Phone:</h4>
              <p><a href="tel:+15064064094" className="text-orange-500 hover:underline">+1 506 406 4094</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
