import React from 'react';
import { Link } from 'react-router-dom';

// Import images (you'll need to add these to your project)
import aiEnergyImage from '../images/ai-energy.webp';
import vehicleAnalyticsImage from '../images/vehicle-analytics.webp';
import cloudSolutionsImage from '../images/cloud-solutions.webp';

// Replace these with actual imports when you have the images
//const aiEnergyImage = "https://via.placeholder.com/800x600.png?text=AI+Energy+Solutions";
//const vehicleAnalyticsImage = "https://via.placeholder.com/800x600.png?text=Vehicle+Analytics";
//const cloudSolutionsImage = "https://via.placeholder.com/800x600.png?text=Cloud+Solutions";

export default function Services() {
  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-12">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <ServiceCard 
            title="AI Energy Solutions"
            description="Optimize energy consumption with our AI-powered monitoring systems. Our solutions help businesses across Canada reduce costs and minimize environmental impact."
            image={aiEnergyImage}
            link="/services/ai-energy"
          />
          <ServiceCard 
            title="Vehicle Analytics"
            description="Enhance your fleet's performance and security with our advanced vehicle analytics. From real-time tracking to predictive maintenance, we've got you covered."
            image={vehicleAnalyticsImage}
            link="/services/vehicle-analytics"
          />
          <ServiceCard 
            title="Cloud Solutions"
            description="Scalable and secure cloud-hosted enterprise solutions tailored for small businesses in Canada. Our expert consulting services ensure smooth implementation and optimization."
            image={cloudSolutionsImage}
            link="/services/cloud-solutions"
          />
        </div>
      </div>
    </div>
  );
}

function ServiceCard({ title, description, image, link }) {
  return (
    <div className="bg-white overflow-hidden shadow-lg rounded-lg">
      <div className="h-48 w-full overflow-hidden">
        <img className="w-full h-full object-cover" src={image} alt={title} />
      </div>
      <div className="p-6">
        <h3 className="text-xl font-semibold text-gray-900 mb-2">{title}</h3>
        <p className="text-gray-600 mb-4">{description}</p>
        <Link 
          to={link} 
          className="text-orange-500 hover:text-orange-600 font-medium"
        >
          Learn More &rarr;
        </Link>
      </div>
    </div>
  );
}
